<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav
        class="left-nav"
        v-scroll-spy-link="{ selector: 'a', class: 'btn-primary' }"
        v-scroll-spy-active="{ selector: 'a div', class: 'btn-primary' }"
      >
        <div class="items">
          <a href="#basics"
            ><div class="btn w-100 btn-simple text-start">
              {{ $t("account.basics") }}
            </div></a
          >
          <a href="#avatar"
            ><div class="btn w-100 btn-simple text-start">
              {{ $t("account.userAvatar") }}
            </div></a
          >
          <a href="#security"
            ><div class="btn w-100 btn-simple text-start">
              {{ $t("account.security") }}
            </div></a
          >
        </div>
      </nav>
    </div>
    <div class="col-md-8 col-lg-10" v-scroll-spy="{ offset: 120 }">
      <section id="basics">
        <h2>
          {{ $t("account.accountSettings") }}
        </h2>
        <h4>
          {{ $t("account.basics") }}
        </h4>
        <form v-on:submit.prevent="edit()" v-if="userEdit && userEdit.email">
          <div class="row text-start custom-input whitebg-input mt-4">
            <div class="row mb-3">
              <div class="col-6">
                <label class="form-label bold-12 text-gray">
                  {{ $t("account.firstName") }}
                </label>
                <input
                  v-model="userEdit.first_name"
                  type="text"
                  class="form-control remove-padding"
                  :placeholder="$t(`account.emailAddress`)"
                />
              </div>
              <div class="col-6">
                <label class="form-label bold-12 text-gray">
                  {{ $t("account.lastName") }}
                </label>
                <input
                  v-model="userEdit.last_name"
                  type="text"
                  class="form-control remove-padding"
                  :placeholder="$t(`account.emailAddress`)"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <label class="form-label bold-12 text-gray">
                  {{ $t("account.email") }}
                </label>
                <input
                  v-model="userEdit.email"
                  type="email"
                  class="form-control remove-padding"
                  :placeholder="$t(`account.emailAddress`)"
                />
              </div>
              <div class="col-6">
                <label class="form-label bold-12 text-gray">
                  {{ $t("account.language") }}
                </label>
                <v-select
                  :options="langs"
                  v-model="userEdit.language_code"
                  label="name"
                  class="remove-padding"
                  :placeholder="$t(`account.notSelected`)"
                  :reduce="(c) => c.code"
                  :getOptionKey="(c) => c.code"
                />
              </div>
            </div>
          </div>
          <div class="row mb-3 me-4">
            <div class="col">
              <div class="error my-2 text-success fw-bold" v-if="userEditOk">
                {{ $t("account.successfulModification") }}
              </div>
              <div
                class="error my-2 text-danger fw-bold"
                v-else-if="userEditError"
              >
                <div v-for="e in userEditError" :key="e">{{ e[0] }}</div>
              </div>
            </div>
            <div class="col text-end">
              <button type="submit" class="btn btn-type-2 btn-normal">
                <span>{{ $t("account.save") }}</span>
              </button>
            </div>
          </div>
        </form>
        <hr class="my-4" />
      </section>
      <section id="avatar">
        <h4>{{ $t("account.userAvatar") }}</h4>
        <div class="d-flex align-items-center my-3 me-3">
          <img :src="user?.avatar_url" class="avatar rounded-circle" />
          <p class="flex-1-1-0 mx-2">
            {{
              $t("account.avatarWillBeShownToYourUsersWhenYouSendThemMessage")
            }}
          </p>
          <input
            type="file"
            ref="avatar"
            v-on:change="uploadAvatar"
            class="d-none"
            accept="image/x-png,image/gif,image/jpeg"
          />
          <button
            type="submit"
            class="btn btn-type-4 btn-normal"
            v-on:click="startUploadAvatar"
          >
            <span>{{ $t("account.uploadNewAvatar") }}</span>
          </button>
        </div>
        <hr class="my-4" />
      </section>
      <section id="security">
        <h4>{{ $t("account.security") }}</h4>
        <h5>{{ $t("account.changePassword") }}</h5>
        <form class="mb-5" v-on:submit.prevent="editPassword()">
          <div class="row text-start custom-input whitebg-input mt-4">
            <div class="row mb-3">
              <div class="col-6">
                <label class="form-label bold-12 text-gray">{{
                  $t("account.previousPassword")
                }}</label>
                <input
                  type="password"
                  class="form-control remove-padding"
                  :placeholder="$t(`account.previousPassword`)"
                  v-model="passwordEdit.old_password"
                />
              </div>
              <div class="col-6">
                <label class="form-label bold-12 text-gray">{{
                  $t("account.newPassword")
                }}</label>
                <Password
                  :placeholder="$t(`account.newPassword`)"
                  class="form-control remove-padding"
                  v-model="passwordEdit.password"
                />
              </div>
            </div>
          </div>
          <div
            class="error my-3 me-4 text-success fw-bold text-end"
            v-if="passwordEditOk"
          >
            {{ $t("account.successfulModification") }}
          </div>
          <div v-if="twofaQR">
            <img :src="twofaQR" />
          </div>
          <div
            class="error my-3 me-4 text-danger fw-bold text-end"
            v-else-if="passwordEditError"
          >
            <div v-for="e in passwordEditError" :key="e">{{ e[0] }}</div>
          </div>
          <div class="row me-3">
            <div class="col">
              <button
                type="button"
                v-on:click="enable2fa"
                class="btn btn-type-4 btn-normal"
              >
                <span>{{ $t("account.setupTwoFactorAuthentication") }}</span>
              </button>
            </div>
            <div class="col text-end">
              <button type="submit" class="btn btn-type-2 btn-normal">
                <span>{{ $t("account.changePassword") }}</span>
              </button>
            </div>
          </div>
        </form>
        <h5>{{ $t("account.loginHistory") }}</h5>
        <div class="white-box my-3" v-if="loginHistory === null">
          <div class="p-3" v-for="i in 5" :key="i">
            <div class="col-12">
              <b class="mx-1"><Skeletor width="180" /></b>
              <span class="text-gray mx-2"><Skeletor width="100" /></span>
            </div>
            <div class="col-12">
              <span class="mx-1"><Skeletor width="70" /></span>
              <span class="mx-1"><Skeletor width="70" /></span>
              <span class="mx-1"><Skeletor width="70" /></span>
            </div>
          </div>
        </div>
        <div class="white-box my-3" v-else>
          <div class="p-3" v-for="history in loginHistory" :key="history.id">
            <div class="col-12">
              <b class="mx-1">
                <span class="text-capitalize">{{ history.device_type }}</span>
                {{ $t("account.in") }}
                <span v-if="history.country === null">{{
                  $t("account.somewhereOnEarth")
                }}</span>
                <span v-else>
                  <span v-if="history.city">{{ history.city }},</span>
                  <span v-if="history.country">{{ history.country }}</span>
                  <span v-if="history.region">({{ history.region }})</span>
                </span>
              </b>
              <span class="text-gray mx-2">{{ time(history.login_at) }}</span>
            </div>
            <div class="col-12">
              <span class="mx-1">{{ history.ip }}</span>
              <span class="mx-1">{{ history.browser }}</span>
              <span class="mx-1">{{ history.platform }}</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Password from "../../components/inputs/Password";
import { useStore } from "vuex";
import http from "../../modules/http";
import date from "@/modules/date";
import tenant from "../../modules/tenant";

export default {
  name: "Account",
  beforeMount() {
    http.fetch("/users/me/login-history").then((data) => {
      this.loginHistory = data.data;
    });
  },
  data() {
    const store = useStore();
    return {
      store: store,
      langs: [
        { code: "en", name: "english" },
        { code: "hu", name: "magyar" },
        { code: "ro", name: "română" },
      ],
      loginHistory: null,
      twofaQR: null,
      passwordEditError: null,
      userEditError: null,
      userEditOk: false,
      passwordEditOk: false,
      userEdit: {
        email: store.state.user?.email,
        first_name: store.state.user?.first_name,
        last_name: store.state.user?.last_name,
        language_code: this.$i18n.locale,
      },
      passwordEdit: {
        old_password: "",
        password: "",
      },
    };
  },
  computed: {
    user() {
      return this.store.state.user;
    },
  },
  watch: {
    user() {
      this.userEdit = {
        email: this.user?.email,
        first_name: this.user?.first_name,
        last_name: this.user?.last_name,
        language_code: this.$i18n.locale,
      };
    },
  },
  methods: {
    time(val) {
      return date.format(val);
    },
    edit() {
      http
        .fetch("/users/me/edit", this.userEdit)
        .then(() => {
          this.userEditError = null;
          this.userEditOk = true;
          this.$store.commit("editUser", this.userEdit);
        })
        .catch((err) => {
          this.userEditOk = false;
          if (err.errors) {
            this.userEditError = err.errors;
          } else {
            this.userEditError = [[err.message]];
          }
        });
      return false;
    },
    enable2fa() {
      http
        .fetch("/users/me/enable-2fa", {
          password: this.passwordEdit.old_password,
        })
        .then((data) => {
          this.passwordEditError = null;
          this.passwordEditOk = true;
          this.twofaQR = data.qr;
        })
        .catch((err) => {
          this.passwordEditOk = false;
          if (err.errors) {
            this.passwordEditError = err.errors;
          } else {
            this.passwordEditError = [[err.message]];
          }
        });
      return false;
    },
    editPassword() {
      http
        .fetch("/users/me/edit-password", this.passwordEdit)
        .then(() => {
          this.passwordEditError = null;
          this.passwordEditOk = true;
        })
        .catch((err) => {
          this.passwordEditOk = false;
          if (err.errors) {
            this.passwordEditError = err.errors;
          } else {
            this.passwordEditError = [[err.message]];
          }
        });
      return false;
    },
    startUploadAvatar() {
      this.$refs.avatar.click();
    },
    uploadAvatar() {
      const _this = this;
      let file = this.$refs.avatar.files[0];

      const request = new XMLHttpRequest();
      const formData = new FormData();
      formData.append("avatar", file, file.name);

      request.open("POST", tenant.getApiBaseUrl() + "/users/me/upload-avatar");

      request.setRequestHeader(
        "Authorization",
        "Bearer " + this.store.state.token
      );
      request.setRequestHeader("Accept", "application/json");

      /*request.upload.onprogress = (e) => {
        progress(e.lengthComputable, e.loaded, e.total);
      };*/

      request.onload = function () {
        if (request.status >= 200 && request.status < 300) {
          let data2 = JSON.parse(request.responseText);
          console.log(data2);
          _this.$store.commit("editAvatar", data2.avatar);
        } else {
          console.log("oh no");
        }
      };

      request.send(formData);
    },
  },
  components: { Password },
};
</script>
